import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import UserTable from 'components/organisms/userTable';
import { hasRole, ROLES } from 'utils/rolesConstants';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const UsersPage = (props) => {
	const { users, profile } = props;
	if (!users) return null;

	const formatted = Object.keys(users).map((key) => ({
		...users[key],
		id: key,
	}));

	const isAdmin =
		hasRole(profile.roles, ROLES.ADMIN) ||
		hasRole(profile.roles, ROLES.SUPER_ADMIN);

	const noAccess = formatted.filter(
		(user) => user.roles && user.roles.length < 1
	);
	const withAccess = formatted.filter(
		(user) => user.roles && user.roles.length > 0
	);
	return (
		<Grid container spacing={4}>
			{isAdmin && noAccess.length > 0 && (
				<Grid item xs={12}>
					<UserTable users={noAccess} title={'Pending'} />
				</Grid>
			)}
			<Grid item xs={12}>
				<UserTable users={withAccess} title={'All Users'} />
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => ({
	users: state.firestore.data.users,
	profile: state.firebase.profile,
});

export default compose(
	firestoreConnect(() => [
		{
			collection: 'users',
			saveAs: 'users',
		},
	]),
	connect(mapStateToProps)
)(UsersPage);
